<template>
        <div class="grid flex-column">
          <div class="col">
        <span class="text-xl font-bold">
          {{ "companyName" in data ? data.companyName : '' }}
        </span>
      </div>
      <div class="col grid grid-nogutter">
        <div class="col-12 md:col-12 lg:col-3 text-lg font-bold align-self-center">
         {{$t("cases.auditDate")}}:
        </div>
        <div class="col-12 md:col-12 lg:col-3 align-self-center">
          <span>
            <template v-if="data.auditDate && data.auditDateEnd">

            {{ data.auditDate }} - {{ data.auditDateEnd }}
            </template>
            <template v-else>
              {{data.certificationDate}}
            </template>
          </span>
        </div>
        <div class="col-12 md:col-12 lg:col-3 align-self-center">
          <span class="text-lg font-bold">{{ $t("cases.certificationType") }}:</span>
        </div>
        <div class="col-5 md:col-12 lg:col-3 align-self-center">
          <span v-if="'certification' in data">{{data.certification.schemeTitle}}</span>
          <span v-else-if="'schemeTitle' in data">{{data.schemeTitle}}</span>
        </div>
      </div>
      </div>
</template>

<script>
export default {
        name: "Date and company info",
        props: ["data"]
}
</script>