<template>
  <CertificationsTable></CertificationsTable>
</template>

<script>
import CertificationsTable from "./CertificationsTable.vue";
export default {
  name: "Certifications",
  components: {
    CertificationsTable
  },
  mounted() {
    this.$action("certification-page-view");
  }
}
</script>