<template>
  <div>
    <Dialog
      :header="$t('certifications.viewCertification')"
      v-model:visible="viewDialog"
      :draggable="false"
      :modal="true"
      :style="{ width: '60vw' }"
    >
      <div v-if="loading" class="flex justify-content-center">
        <ProgressSpinner />
      </div>
      <div v-else>
        <!-- <iframe :src="invoiceView" class="w-full" style="height: 50rem" frameborder="0"></iframe> -->
        <RenderFile></RenderFile>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RenderFile from "../RenderFile";
export default {
  name: "View invoice",
  components: {
    RenderFile,
  },
  props: ["display", "loading"],
  emits: ["closeFileCertificate"],
  data() {
    return {
      viewDialog: false,
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      }
    },
  },
  computed: {
    ...mapGetters(["fileToView"]),
  },
  methods: {
    close(val) {
      this.$emit("closeFileCertificate", val);
      window.URL.revokeObjectURL(this.fileToView);
    },
  },
};
</script>
