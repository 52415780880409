<template>
  <ViewCertificate
    :display="openViewCertificate"
    :dataValue="selectedCertificate"
    @closeViewCertificate="closeView"
  ></ViewCertificate>
  <AddRating
    :display="openAddRating"
    :certData="addRatingCase"
    @closeAddRating="closeAddRating"
  >
  </AddRating>
  <Card>
    <template #title v-if="loading || certifications.length > 0">
      <div class="grid grid-nogutter">
        <div class="col-6 md:col-10 text-left">
          {{ $t("certifications.title") }}
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
      </div>
    </template>

    <template #content v-if="loading || certifications.length > 0">
      <DataTable
        :value="certifications"
        :paginator="true"
        :rows="10"
        :loading="loading"
        :filters="filters"
        ref="tableCertifications"
        dataKey="id"
        showGridlines
        class="p-datatable-certifications"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        removableSort
        breakpoint="1143px"
        sortField="certificationDate"
        :sortOrder="-1"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <Column
          field="id"
          :header="$t('certifications.certificationId')"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="text-right">{{ data.id }}</span>
          </template>
        </Column>
        <Column
          field="companyName"
          :header="$t('certifications.companyName')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyName }}
          </template>
        </Column>
        <Column
          field="tempCertificationDate"
          :header="$t('certifications.certificationDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{
              $dayjs(data.certificationDate, "DD/MM/YYYY").format("DD/MM/YYYY")
            }}
          </template>
        </Column>

        <Column
          field="certificatorId"
          :header="$t('certifications.certificator')"
          :sortable="true"
        >
          <template #body="{ data }">
            <div class="text-right md:text-left">
              {{ data.certificatorData.firstName }}
              {{ data.certificatorData.lastName }}
            </div>
          </template>
        </Column>
        <Column
          field="status"
          :header="$t('certifications.status')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ translatedStatus(data.status) }}
          </template>
        </Column>
        <Column
          field="schemeTitle"
          :header="$t('global.certificationType')"
          :sortable="true"
        >
        </Column>
         <Column
          field="certificationCode"
          :header="$t('certifications.certification')"
          :sortable="true"
        >
        <template #body="{ data }">
            CERT-{{data.certificationCode}}
        </template>
        </Column>
        <Column
          field="Actions"
          :header="$t('global.actions')"
          :exportable="false"
        >
          <template #body="{ data }">
            <div class="flex justify-content-evenly">
              <Button
                class="p-button-succes"
                :label="$t('global.view')"
                @click="openView(data)"
              />
              <!-- @click="viewPdf(data.id)" -->
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #content v-else-if="!loading">
      <NoTableData :type="'certifications'"></NoTableData>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mapGetters } from "vuex";
import NoTableData from "../NoTableData.vue";
import ViewCertificate from "./ViewCertificate.vue";
import AddRating from "./AddRating.vue";

export default {
  name: "Certifications table",
  components: {
    NoTableData,
    ViewCertificate,
    AddRating,
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      loading: false,
      selectedCertificate: {},
      openViewCertificate: false,
      openAddRating: false,
      addRatingCase: {},
    };
  },
  computed: {
    ...mapGetters([
      "certifications",
      "checkRole",
      "translatedStatus",
      "isMobile",
    ]),
  },
  async mounted() {
    await this.getCertifications();
    if (
        this.$route.name != "clientInformation" &&
        "certId" in this.$route.query &&
        this.$route.query.certId.length != 0 &&
        "showRating" in this.$route.query &&
        this.$route.query.showRating == "true"
      ) {
         this.openAddRating = true;
        this.addRatingCase = this.certifications.find((el) => el.id == this.$route.query.certId);
      }
      else if (
        this.$route.name != "clientInformation" &&
        "certId" in this.$route.query &&
        this.$route.query.certId.length != 0
      ) {
        this.openView(
          this.certifications.find((el) => el.id == this.$route.query.certId)
        );
      }
  },
  methods: {
    async getCertifications() {
      this.loading = true;
      await this.$store.dispatch("certifications", {
        userId: "userId" in this.$route.query ? this.$route.query.userId : "",
      });
      this.loading = false;
      console.log(this.$route);
    },
    exportCSV() {
      this.$refs.tableCertifications.exportCSV();
      this.$action("export-certifications");
    },
    // viewPdf(id) {
    //   this.$store.dispatch("downloadCertification", {
    //     id: id,
    //     download: false,
    //   });
    // },
    openView(data) {
      this.selectedCertificate = { ...data };
      this.openViewCertificate = true;
    },
    async closeView({ showRating, showRatingData, updateCertificationsList }) {
      this.openViewCertificate = false;
      this.selectedCertificate = {};

      if (showRating && this.checkRole(["client"])) {
        this.openAddRating = true;
        this.addRatingCase = { ...showRatingData };
      }
      if (updateCertificationsList) {
        this.getCertifications();
      }
    },
    closeAddRating() {
      this.openAddRating = false;
      this.addRatingCase = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  height: 7%;
  width: 7%;
  margin-bottom: 5%;
}

.certifications {
  padding-top: 5%;
  padding-bottom: 2%;
  border-style: solid;
  border-color: #f9f9f9;
}
</style>
