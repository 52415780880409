<template>
  <ViewCertificateFile
    :display="showView"
    :loading="loadView"
    @closeFileCertificate="closeViewFile"
  ></ViewCertificateFile>
  <Dialog
    :header="`${$t('certifications.certificationForCase')} #${data.caseId}`"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '50vw' }"
  >
    <DateAndCompanyInfo :data="data"></DateAndCompanyInfo>
    <p class="text-xl font-bold m-0">
      {{ $t("certifications.certificationCopies") }}
    </p>
    <p class="text-base text-500">
      {{ $t("certifications.selectCertificationLanguage") }}
    </p>
    <div
      class="flex justify-content-between align-items-center p-3 pr-6 border-1 border-solid border-black-alpha-90"
    >
      <p class="m-0">{{ $t("global.language") }}</p>
      <p>{{ $t("global.actions") }}</p>
    </div>
    <div class="flex justify-content-between align-items-center pl-3 pt-3">
      <p class="m-0">{{ $t("global.english") }}</p>
      <div class="flex justify-content-between">
        <Button
          class="p-button-text mdi mdi-24px mdi-eye"
          @click="downloadViewPdf(data.id, false, 'en')"
        />
        <Button
          class="p-button-text mdi mdi-24px"
          :class="englishDownload ? '' : 'mdi-download'"
          @click="downloadViewPdf(data.id, true, 'en')"
          :loading="englishDownload"
        />
      </div>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between align-items-center pl-3 pt-3">
      <p class="m-0">{{ $t("global.greek") }}</p>
      <div class="flex justify-content-between">
        <Button
          class="p-button-text mdi mdi-24px mdi-eye"
          @click="downloadViewPdf(data.id, false, 'el')"
        />
        <Button
          class="p-button-text mdi mdi-24px"
          :class="greekDownload ? '' : 'mdi-download'"
          @click="downloadViewPdf(data.id, true, 'el')"
          :loading="greekDownload"
        />
      </div>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between align-items-center pl-3 pt-3">
      <p class="m-0">{{ $t("global.chinese") }}</p>
      <div class="flex justify-content-between">
        <Button
          class="p-button-text mdi mdi-24px mdi-eye"
          @click="downloadViewPdf(data.id, false, 'zh')"
        />
        <Button
          class="p-button-text mdi mdi-24px"
          :class="chineseDownload ? '' : 'mdi-download'"
          @click="downloadViewPdf(data.id, true, 'zh')"
          :loading="chineseDownload"
        />
      </div>
    </div>
    <Divider class="my-2" />
    <template #footer>
      <Button
        :label="$t('global.close')"
        @click="close"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import DateAndCompanyInfo from "../Cases/DateAndCompanyInfo";
import ViewCertificateFile from "./ViewCertificateFile";
export default {
  name: "View certificate",
  components: {
    DateAndCompanyInfo,
    ViewCertificateFile,
  },
  props: ["display", "dataValue"],
  emits: ["closeViewCertificate"],
  data() {
    return {
      viewDialog: false,
      data: {},
      showRating: false,
      showRatingData: {},
      greekDownload: false,
      chineseDownload: false,
      englishDownload: false,
      showView: false,
      loadView: false,
      getCertifications: false
    };
  },
  computed: {
    ...mapGetters(["checkRole", "fileToView"]),
  },
  watch: {
    display(val) {
      console.log(this.dataValue);
      this.viewDialog = val;
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        this.showRating = false;
        this.getCertifications = false;
        this.showRatingData = {};
        if (
          this.dataValue.downloaded &&
          this.dataValue.showRatingPopup
        ) {
          this.showRating = true;
          this.showRatingData = { ...this.dataValue };
        }
      }
    },
    dataValue() {
      this.data = { ...this.dataValue };
    },
  },
  methods: {
    close() {
      this.$emit("closeViewCertificate", {
        showRating: this.showRating,
        showRatingData: this.showRatingData,
        updateCertificationsList: this.getCertifications
      });
    },
    closeViewFile() {
      this.showView = false;
      window.URL.revokeObjectURL(this.fileToView);
    },
    async downloadViewPdf(id, download = true, language) {
      if (download) {
        if (language == "el") {
          this.greekDownload = true;
        } else if (language == "zh") {
          this.chineseDownload = true;
        } else {
          this.englishDownload = true;
        }
      }

      if (!download) {
        this.loadView = true;
        this.showView = true;
      }
      await this.$store
        .dispatch("downloadCertification", {
          id,
          download,
          language,
        })
        .then(() => {
          if (this.checkRole["client"]) {
            this.$action("certification-view", { certificationId: id });
          }
        });
      this.loadView = false;

      this.greekDownload = false;
      this.chineseDownload = false;
      this.englishDownload = false;
      if (this.dataValue.downloaded == false && download) {
        this.getCertifications = true;
        this.showRating = true;
        this.showRatingData = { ...this.dataValue };
      }
    },
  },
};
</script>
