<template>
  <Dialog
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :closable="false"
    :style="{ width: '30vw' }"
  >
    <p class="text-center font-bold text-base">
      {{ $t("cases.howWasYouExpirience", [certification.id]) }}
    </p>
    <p class="text-center font-light">{{ $t("cases.feedBack") }}</p>
    <Rating class="text-center mt-6 mb-5" v-model="rating" :cancel="false" />
    <p class="text-center font-bold text-base">
      {{ $t("cases.additionalComments") }}
    </p>
    <div class="text-center">
      <Textarea
        v-model="ratingComments"
        :autoResize="true"
        class="w-full h-10rem"
      />
    </div>
    <template #footer>
      <div class="flex justify-content-between">
        <Button
          :label="$t('global.submit')"
          class="p-button-success"
          :loading="loading"
          iconPos="right"
          :disabled="disableSubmit"
          @click="addRating"
        />
        <Button
          :label="$t('global.close')"
          @click="cancel"
          class="p-button-danger"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "Add invoice",
  props: ["display", "certData"],
  emits: ["closeAddRating"],
  data() {
    return {
      viewDialog: false,
      loading: false,
      rating: null,
      ratingComments: "",
      disableSubmit: true,
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      }
    },
    rating(val) {
      if (val != null) {
        this.disableSubmit = false;
      }
    },
  },
  computed: {
    certification() {
      return {
        ...this.certData,
      };
    },
  },
  methods: {
    cancel() {
      this.$store.dispatch("updateShowRatingValue", this.certification.id);
      this.close();
    },
    close(success = false) {
      this.$emit("closeAddRating", success);
    },
    async addRating() {
      this.loading = true;
      await this.$store.dispatch("addRating", {
        id: this.certification.caseId,
        rating: this.rating,
        comments: this.ratingComments,
      });
      this.close();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .p-rating-icon {
    font-size: 3rem !important;
  }
}
</style>
